import { createSlice } from "@reduxjs/toolkit";
import levels from "./levelRoleGroups";

const lan = localStorage.getItem("language") || "id";
const name = localStorage.getItem("languageName") || "Indonesia";


const state = {
  errorMessage: "",
  loading: false,
  flightRecordArrivals: [],
  name,
  levels,
  rules: {},
};

export const appSlice = createSlice({
  name: "apps",
  initialState: state,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    unsetLoading(state) {
      state.loading = false;
    },
    setLanguage(state, payload) {
      state.language = payload.payload.language;
      state.name = payload.payload.name;
      localStorage.setItem("language", payload.payload.language);
      localStorage.setItem("languageName", payload.payload.name);
    }
  },
});

export default appSlice.reducer;
