import React from 'react'
import logo from "@/assets/media/brs_logo.png";

export function LoadingGreen({style, ...props}) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "center",...style, backgroundColor: "#090326"}} {...props}>
            <img width={"10%"} src={logo} />
            <img width={"6%"} style={{marginTop: "-1rem"}}src='/assets/animation/loading.svg'></img>
        </div>
    )
}


export function LoadingGreenSpin({style, ...props}) {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "center",...style}} {...props}>
            {/* <img width={"4%"} src={logo} /> */}
            <img width={"5%"} style={{}}src='/assets/animation/loading.svg'></img>
        </div>
    )
}