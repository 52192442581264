export default function levelRoleGroups() {
  return [
    {
      level: 0x1000,
      value: "Super Admin",
    },
    {
      level: 0x10,
      value: "Sistik",
    },
    {
      level: 0x04,
      value: "Subdit TPI",
    },
    {
      level: 0x08,
      value: "Kanwil",
    },
    {
      level: 0x02,
      value: "Kanim",
    },
    {
      level: 0x01,
      value: "TPI",
    },
  ];
};
